<template>
  <div class="col-10 mx-auto justify-content-center">
    <h3>Nowe Spotkanie</h3>
    <!-- <h5></h5> -->
    <input
      class="form-control mt-3 mb-1"
      type="text"
      placeholder="Nazwa"
      v-model="meetingName"
      
    />
    <textarea
      class="form-control mt-3 mb-1"
      type="text"
      placeholder="Adres"
      v-model="meetingAddress"
    />
    <input
      class="form-control mt-3 mb-1"
      type="text"
      placeholder="Telefon"
      v-model="meetingPhoneNumber"
    />
    <input
      class="form-control mt-3 mb-1"
      type="text"
      placeholder="Adres email"
      v-model="meetingEmail"
    />
    <!-- <button class="btn btn-primary btn-lg" @click="openUnitModal(meetingUnit)">Wybierz urządzenie</button> -->

    <!-- <textarea
      class="form-control mt-3 mb-1"
      type="text"
      placeholder="Szczegóły oferty"
      v-model="subheader"
    /> -->

    <input  class="form-control mt-3 mb-1" placeholder="Szukaj urządzenie" v-model="search" @input="searchProduct(search)" />
    <div v-if="selectedBrand" class="row d-flex flex-wrap align-items-center">
      <!-- <div v-for="item in selectedProducts" class="mx-auto" style="width:300px;height:200px;background-position: bottom; 
    background-size: cover; border:1px solid lightgrey; padding:10px; margin: 10px" v-bind:style="{backgroundImage:'url('+ item.image_link+')'}" @click="selectProduct(item)"> -->
    <div v-for="item in selectedProducts" :key="item.id" class="mx-auto col-4" style="height:200px;background-position: bottom;background-size: cover; border:1px solid lightgrey; padding:10px; margin: 10px" @click="selectProduct(item)">
        <div>
          <p style="font-weight: 700;">{{ item.title }}</p><br>
         
          <img width="auto" style="margin-top:-20px;max-height: 100px;" :src="item.image_link" alt=""> 
          <p style="font-weight: 700;">{{ item.price }} zł </p>
        </div>
      </div>
    </div>
    <form class="add-form bg-light border" @submit.prevent>
      <table class="table table-bordered">
        <thead>
          <th>przedmiot</th>
          <th>ilość</th>
          <th>jednostka miary</th>
          <th>cena netto</th>
          <th>wartość netto</th>
          <th>wartość brutto</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in meetingsElements" :key="item.id">
            <td>
              
              
              <input
                type="text"
                class="netto form-control mt-1 mb-1"
                v-model="item.name"
                :key="item.id"
              />
              <!-- <button v-if="item.id ==1" class="btn btn-primary btn-lg" @click="openUnitModal(meetingUnit)">Wybierz urządzenie</button> -->
            </td>
            
            <td>
              <input
                type="number"
                class="netto form-control mt-1 mb-1"
                v-model="item.amount"
                :key="item.id"
                step="0.01"
                @change="total()"
              />
            </td>
            <td>
              <select
                class="form-control mt-1 mb-3"
                type="select"
                v-model="item.measure"
                :key="item.id"
              >
              <option value="m">Metry</option>
              <option value="szt">Sztuki</option>
              <option value="l">Litry</option>
              <option value="kg">Kilogramy</option>
            </select>
            </td>
            <td>
              <input
                type="number"
                class="netto form-control mt-1 mb-1"
                :id="index"
                v-model="item.price"
                :key="item.id"
                step="0.01"
                @change="total()"
              />
            </td>
            <td>
              <span v-if="item.price">{{ (item.price * item.amount).toFixed(2) }}</span>
            </td>
            <td>
              <span v-if="item.price">{{
                (item.price * item.amount * vat).toFixed(2)
              }}</span>
            </td>
            <td>
              <button class="btn btn-small btn-danger" @click="deleteItem(index)">
                usuń
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Suma</td>
            <td>
              <select
                class="form-control mt-1 mb-1"
                name="vat"
                id="vat-value"
                v-model="vat"
                @change="total()"
              >
                <option value="0">VAT</option>
                <option value="1.08">8%</option>
                <option value="1.23">23%</option>
              </select>
            </td>
            <td></td>
            <td></td>
            <td>{{ totalPriceNetto.toFixed(2) }}</td>
            <td>{{ totalPriceBrutto.toFixed(2) }}</td>
          </tr>
        </tfoot>
      </table>
      <button
        style="width: 100%; height: 65px"
        class="btn btn-success btn-sm mt-1"
        @click="addElement()"
      >
        Dodaj element
      </button>
      <label style="cursor: pointer" for="customInputFile">
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          class="bi bi-camera"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
          />
          <path
            fill-rule="evenodd"
            d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
          />
          <path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
        </svg>
      </label>

      <input
        style="display: none"
        id="customInputFile"
        class="custom-file-input"
        type="file"
        accept="image/*"
        @change="uploadImage"
      />

      <img width="100%" :src="`${imageURL}`" alt="" />
      <textarea
        class="form-control mt-3 mb-1"
        type="text"
        placeholder="Uwagi do oferty"
        v-model="notice"
      />

      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addMeeting()"
      >
        Dodaj Spotkanie
      </button>
    </form>

    <!-- <unit-modal
      
      @closeModal="closeModal()" :item="unitModal"></unit-modal> -->
      

  </div>
</template>
<script>
// import func from "vue-editor-bridge";
import { mapState } from "vuex";
import axios from 'axios';

// import UnitModal from "../components/UnitModal.vue";

// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

export default {
  data() {
    return {
      //   total: "",
      date: new Date(),
      vat: 0,
      totalPriceNetto: 0,
      totalPriceBrutto: 0,
      selectedBrand:false,
      search:"",
      meetingEmail:"",
      meetingAddress:"",
      meetingPhoneNumber:"",
      meetingName:"",
      meetingUnit:"Klimatyzator",
      offerHeader:
        "Szczegółowa kalkulacja cenowa: Klimatyzator z Wi-Fi <span style='color:red'>5 Lat Gwarancji</span>",
      imageURL: "",
      notice: "Oferta ważna 14 dni. ",
      header:
        " Niniejsza oferta cenowa zawiera szczegółową kalkulację na dostawę,<br />montaż i uruchomienie klimatyzatora.",
      subheader:
        "W ramach przedstawionej oferty gwarantujemy: <br /><br /> ➢ Stały serwis gwarancyjny na wykonane roboty i dostarczone urządzenia <br /> ➢ Serwis konserwatorski po upływie okresu gwarancyjnego <br /> ➢ Serwis sprzątający podczas instalacji urządzeń klimatyzacyjnych  <br />➢ Szkolenie z zakresu obsługi urządzenia",
    };
  },
  components: {
    // "unit-modal": UnitModal,
  },
  computed: {
    ...mapState(["userProfile", "meetingsElements","RotensoInUnit", "others"]),
    offerNumber() {
      let data = new Date().toLocaleDateString();
      data = data.replace(/\./g, "/");
      return this.others[0].value + "/" + data;
    },
  },
  methods: {
    selectBrand(brand) {
      console.log(this.products)
      console.log(typeof(this.products))
      this.selectedBrand = true;
      this.brandProducts = []
      
      if(brand != "Inne") {this.products.forEach(item => {
        
        if(item.brand[0] == brand){
         this.brandProducts.push(item)
        }});
      } else {
        this.products.forEach(item => {
        if(item.brand[0] != "Rotenso" && item.brand[0] != "Sinclair"){
         this.brandProducts.push(item)
        }});
      }
      
    },
    searchProduct(searchQuery){
      this.selectedBrand = true;
      if (searchQuery === ""){
        this.selectedBrand = false;
      }
      // this.brandProducts.filter((product) => product.title.toLowerCase().includes(searchQuery.toLowerCase()))
      this.selectedProducts = this.products.filter((product) => product.title.toLowerCase().includes(searchQuery.toLowerCase()))
    },
    selectProduct(selected){
      this.meetingsElements.unshift({ name: selected.title, amount:1, measure:"szt", price: selected.price });
      this.selectedBrand = false;
      this.imageURL = selected.image_link;
      this.total();
      // this.seectedProduct = selected;
    },
    addMeeting() {
      console.log( {
        contractor: this.meetingEmail,
        date: this.date,
        offer: this.meetingsElements,
        vat: this.vat,
        contractorAddress:this.meetingAddress,
        contractorNumber: this.meetingPhoneNumber,
        contractorName: this.meetingName,
        netto: this.totalPriceNetto,
        brutto: this.totalPriceBrutto,
        imageUrl: this.imageURL,
        header: this.header,
        notice: this.notice,
        user: this.user,
        header2: this.header2,
        subheader: this.subheader,
        name: this.meetingName,
        email: this.meetingEmail,
        phone: this.meetingPhoneNumber,
        address: this.meetingAddress
    })
      // if (this.contractor) prompt("Nazwa klienta/Adres Email"){
      this.$store.dispatch("createMeeting", {
        contractor: this.meetingEmail,
        date: this.date,
        offer: this.meetingsElements,
        vat: this.vat,
        imageUrl: this.imageURL,
        contractorAddress: this.meetingAddress,
        contractorNumber: this.meetingPhoneNumber,
        contractorName: this.meetingName,
        notice: this.notice,
        netto: this.totalPriceNetto,
        brutto: this.totalPriceBrutto,
        offerNumber: "Spotkanie",
        user: this.user,
        offerHeader: this.offerHeader,
        header: this.header,
        subheader: this.subheader,
        // offerNumber: this.offerNumber,
      });
    
      // this.$store.dispatch("updateCounter", this.number).then(() => {
      //   this.$emit("closeModal");
      // });
      // } else {
      //   alert("brak kontrahenta");
      
    },

    openUnitModal(event) {
      console.log(event);
      this.$modal.show("unit-modal");
      this.item = event;
      // Prevent navigating to narrower view (default vue-cal behavior).
      // e.stopPropagation();
    },
    closeModal() {
      this.$modal.hide("unit-modal");
    },
    // openOfferModal(offer) {
    //   window.scrollTo(0, 0);
    //   this.$modal.show("offer-modal");
    //   this.offer = offer;
    //   // Prevent navigating to narrower view (default vue-cal behavior).
    // },
    // closeModal() {
    //   setTimeout(() => {
    //     // this.events = [];
    //     // console.log(this.installations);
    //     //   //   const item1 = this.config.chart.items["1"];
    //     //   //   item1.label = "label changed dynamically";
    //     //   //   item1.time.end += 2 * 24 * 60 * 60 * 1000;
    //     // this.setEvents();
    //   }, 100);
    //   this.$modal.hide("offer-modal");
    // },
    addElement() {
      this.meetingsElements.push({ name: "wpisz nazwę" });
      this.total();
    },
    deleteItem(e) {
      console.log(e)
      console.log(this.meetingsElements)
      this.meetingsElements.splice(e, 1);
      this.total();
    },

    uploadImage() {
      var img = document.getElementById("customInputFile").files[0];
      this.getBase(img, (response) => {
        this.imageURL = response;
      });
    },

    getBase(img, callback) {
      var reader = new FileReader();
      reader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result;
        callback(srcData);
      };
      reader.readAsDataURL(img);
      //   console.log("outer", this.imageURL);
    },

    total() {
      this.totalPriceNetto = 0;
      this.totalPriceBrutto = 0;
      this.meetingsElements.forEach((item) => {
        if (item.price) {
          this.totalPriceNetto += item.price * item.amount;
          this.totalPriceBrutto += item.price * item.amount * this.vat;
        }
      });
    },
  },
  mounted(){
    axios.get('https://klim-bud.pl/wp-json/twoqbs/v1/units').then((response) => {
      this.products = response.data
      this.products = this.products.product
      console.log(this.products)
    })
  }
};
</script>
